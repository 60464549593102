<template>
  <div class="titleBox">
    <p>{{ hhmm }}</p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      hhmm: '00:00'
    }
  },
  beforeDestroy() {
    this.timeCurrent && clearInterval(this.timeCurrent)
  },
  mounted() {
    this.timeCurrent = setInterval(() => {
      this.updateTimeDisplay()
    }, 30000);
    this.updateTimeDisplay()
  },
  methods: {
    updateTimeDisplay() {
      const date = new Date()
      const h = date.getHours()
      const m = date.getMinutes()
      const hhmm = `${this.parseZero(h)}:${this.parseZero(m)}`
      this.hhmm = hhmm
    },
    parseZero(m, n = 2) {
      return (Array(n).join(0) + m).slice(-n)
    },
  }
}
</script>

<style lang="scss" scoped>
.titleBox {
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    margin-top: 5px;
    font-size: 48px;
    color: #fff;
    font-family: PingFang SC;
  }

  &::before {
    content: '';
    display: block;
    width: 120px;
    height: 41px;
    background: url("./../../../assets/images/pc/now_time.png") center no-repeat;
    background-size: 80% auto;
  }

  &::after {
    content: '';
    display: block;
    margin-left: 5px;
    margin-top: -5px;
    width: 110px;
    height: 18px;
    background: url("./../../../assets/images/pc/now_time_b.png") center bottom no-repeat;
    background-size: 100% auto;
  }
}
</style>