<template>
  <div class="pcm-wrapper" id="pcm-wrapper">
    <video muted loop autoplay>
      <source src="../../../assets/images/pc/bg.mp4" />
    </video>
    <div class="logo"></div>
    <div class="contactBox">
      <div class="telegramLogo" @click="toTg"></div>
      <div class="potatoLogo" @click="toPotato"></div>
    </div>
    <div class="titleBox">
      <p>{{ hhmm }}</p>
    </div>
    <div class="btnBox">
      <div class="qrBox">
        <img class="qrcodeBox" :src="qrImg" alt="" />
      </div>
      <div class="tip"></div>
    </div>
  </div>
</template>

<script>
import autofit from "autofit.js";
autofit.init();
export default {
  props: ["qrImg"],
  data() {
    return {
      hhmm: '00:00'
    }
  },
  mounted() {
    autofit.init(
      {
        dh: 1080,
        dw: 1920,
        el: "#pcm-wrapper",
        resize: true,
      },
      false
    ); // 可关闭控制台运行提示输出
    this.timeCurrent = setInterval(() => {
      this.updateTimeDisplay()
    }, 30000);
    this.updateTimeDisplay()
  },
  beforeDestroy() {
    this.timeCurrent && clearInterval(this.timeCurrent)
  },
  methods: {
    updateTimeDisplay() {
      const date = new Date()
      const h = date.getHours()
      const m = date.getMinutes()
      const hhmm = `${this.parseZero(h)}:${this.parseZero(m)}`
      this.hhmm = hhmm
    },
    parseZero(m, n) {
      return (Array(n).join(0) + m).slice(-n)
    },
    toTg() {
      window.open("https://t.me/liangnianbanlaosiji");
    },
    toPotato() {
      window.open("https://dlptm.org/lnbsq");
    },
  },
};
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  display: none;
}

.pcm-wrapper {
  position: relative;
  width: 100vw;
  height: 100vh;

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .logo {
    position: fixed;
    top: 40px;
    left: 60px;
    width: 257px;
    height: 80px;
    background: url("./../../../assets/images/pc/logo.png") no-repeat;
    background-size: 90% auto;
  }

  .contactBox {
    position: fixed;
    top: 30px;
    right: 12.5%;
    display: flex;
    align-items: center;

    .telegramLogo {
      width: 68px;
      height: 68px;
      margin-right: 23px;
      background: url("./../../../assets/images/pc/telegram.png") no-repeat;
      background-size: 100% 100%;
    }

    .potatoLogo {
      width: 68px;
      height: 68px;
      background: url("./../../../assets/images/pc/potato.png") no-repeat;
      background-size: 100% 100%;
    }
  }

  .titleBox {
    position: fixed;
    width: 308px;
    left: calc(50% - 154px);
    top: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      font-size: 122px;
      color: #fff;
      font-family: YEFONTAoYeHei;
    }

    &::before {
      content: '';
      display: block;
      width: 130px;
      height: 54px;
      background: url("./../../../assets/images/pc/now_time.png") no-repeat;
      background-size: 100% 100%;
    }

    &::after {
      content: '';
      display: block;
      margin-top: -25px;
      margin-left: 5px;
      width: 260px;
      height: 48px;
      background: url("./../../../assets/images/pc/now_time_b.png") center bottom no-repeat;
      background-size: 100% auto;
    }
  }

  .btnBox {
    position: fixed;
    bottom: 20px;
    right: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .qrBox {
      width: 130px;
      height: 130px;
      background: #D9D9D9;
      display: flex;
      align-items: center;
      justify-content: center;

      .qrcodeBox {
        width: 100%;
        height: 100%;
      }
    }

    .tip {
      margin-top: 10px;
      width: 226px;
      height: 22px;
      background: url("./../../../assets/images/pc/tip.png") no-repeat;
      background-size: 100% auto;
    }
  }
}
</style>
